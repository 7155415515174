import {
  historySchema,
  IAmSupportSchema,
  markAsReadSchema,
  messageSchema,
  messageWasReadSchema,
  roomSchema,
  sendMessageSchema,
  typingUsersSchema,
  UserListSchema,
  whoRUSchema,
} from 'chat-schemas'
import { userSchema } from 'chat-schemas/user.schema'
import { Send } from 'lucide-react'
import { useEffect, useRef, useState } from 'react'
import { io } from 'socket.io-client'
import { z } from 'zod'
import { Carousel } from './Carousel'
import { Modal } from './Modal'
import { PlaceholderMessage } from './PlaceholderMessage'
import { SupportMessage } from './SupportMessage'
import { UserMessage } from './UserMessage'

export default function SupportChat({
  room,
  readAllMessages,
  displayName,
  serverUrl,
  userId,
  userSecret,
}: {
  room: z.infer<typeof roomSchema>
  readAllMessages: () => void
  displayName: string
  serverUrl: string
  userId: string
  userSecret: string
}) {
  const [messages, setMessages] = useState<z.infer<typeof messageSchema>[]>([])
  const [inputText, setInputText] = useState('')
  const [socket, setSocket] = useState<ReturnType<typeof io> | null>(null)
  const [users, setUsers] = useState<z.infer<typeof userSchema>[]>([])
  const [typingUsers, setTypingUsers] = useState<string[]>([])
  const [carouselData, setCarouselData] = useState<{
    images: string[]
    startIndex: number
  } | null>(null)

  useEffect(() => {
    const sock = io(serverUrl, { auth: { userId, userSecret } })
    setSocket(sock)

    return () => {
      sock.disconnect()
    }
  }, [room.id, serverUrl, userId, userSecret])

  const messagesEndRef = useRef<HTMLDivElement>(null)

  const scrollToBottom = () => {
    if (messages.length > 0) {
      // wait 10 ms for the page to be fully displayed
      setTimeout(
        () => messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' }),
        10
      )
    }
  }

  useEffect(scrollToBottom, [messages, typingUsers])

  useEffect(() => {
    if (!socket) return
    socket.on('whoRU', (_message) => {
      const whoRU = whoRUSchema.safeParse(_message)
      if (!whoRU.success) {
        console.error(whoRU.error)
      }
      socket.emit('IAmSupport', {
        user: { name: displayName, role: 'supporter' },
        roomId: room.id,
      } as z.infer<typeof IAmSupportSchema>)
    })

    socket.on('userList', ({ users }: z.infer<typeof UserListSchema>) => {
      setUsers(users)
    })

    socket.on('history', ({ messages }: z.infer<typeof historySchema>) => {
      for (const { id: messageId } of messages.filter(
        (m) => m.userId !== userId && !m.isRead
      )) {
        socket.emit('markAsRead', { messageId } as z.infer<
          typeof markAsReadSchema
        >)
      }

      setMessages(messages)
      readAllMessages()
    })

    socket.on('message', (message: z.infer<typeof messageSchema>) => {
      setMessages((messages) => [...messages, message])
      socket.emit('markAsRead', { messageId: message.id } as z.infer<
        typeof markAsReadSchema
      >)
    })

    socket.on(
      'messageWasRead',
      (messageWasRead: z.infer<typeof messageWasReadSchema>) => {
        console.log('message was read', messageWasRead)
        setMessages((messages) =>
          messages.map((m) =>
            m.id === messageWasRead.messageId ? { ...m, isRead: true } : m
          )
        )
      }
    )

    socket.on(
      'typingUsers',
      (typingUsers: z.infer<typeof typingUsersSchema>) => {
        setTypingUsers(typingUsers.typingUsers)
      }
    )

    return () => {
      socket.removeAllListeners()
    }
  }, [socket, room.id, readAllMessages, displayName, userId])

  function handleSend() {
    if (!inputText) return
    socket?.emit('sendMessage', { message: inputText, images: [] } as z.infer<
      typeof sendMessageSchema
    >)
    setInputText('')
  }

  return (
    <div className="flex flex-col h-full w-full border border-gray-300 overflow-hidden bg-gray-100">
      {carouselData && (
        <Modal isOpen={!!carouselData} onClose={() => setCarouselData(null)}>
          <Carousel {...carouselData} />
        </Modal>
      )}
      <div className="bg-blue-600 text-white p-4">
        <h2 className="text-lg font-semibold">{room.name ?? room.id}</h2>
      </div>
      <div className="flex-grow overflow-y-auto p-4 space-y-4">
        {messages.map((m) =>
          users.find((u) => u.id === m.userId)?.role === 'supporter' ? (
            <SupportMessage
              key={m.id}
              message={m}
              users={users}
              isRead={m.isRead}
            />
          ) : (
            <UserMessage
              key={m.id}
              message={m}
              user={users.find((u) => u.id === m.userId)}
              setCarouselData={setCarouselData}
            />
          )
        )}
        {typingUsers.map((userId) => (
          <PlaceholderMessage
            key={userId}
            user={users.find((u) => u.id === userId)}
          />
        ))}
        <div ref={messagesEndRef} />
      </div>
      <div className="p-4 bg-white border-t border-gray-300">
        <div className="flex items-stretch gap-1">
          <input
            type="text"
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && handleSend()}
            className="flex-grow p-2 border border-gray-300 rounded-l-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Schreiben Sie eine Nachricht..."
          />
          <button
            onClick={handleSend}
            disabled={!inputText}
            className={
              'px-4 rounded-r-lg flex items-center justify-center ' +
              (!inputText
                ? 'bg-gray-300 text-gray-500'
                : 'bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500')
            }
          >
            <Send size={20} />
          </button>
        </div>
      </div>
    </div>
  )
}
