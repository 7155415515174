import {
  adminOpCenterListSchema,
  IAmSupportSchema,
  opCenterCreatedSchema,
  roomListSchema,
  roomSchema,
  supporterListSchema,
  unreadMessagesUpdateSchema,
  userCreatedSchema,
} from 'chat-schemas'
import { LogOut, RefreshCcw } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { io } from 'socket.io-client'
import { z } from 'zod'
import './App.css'
import { Modal } from './Modal'
import { SignIn } from './SignIn'
import SupportChat from './SupportChat'

function App() {
  const [searchParams] = useSearchParams()

  const [rooms, setRooms] = useState<z.infer<typeof roomSchema>[]>([])
  const [socket, setSocket] = useState<ReturnType<typeof io> | null>(null)
  const [selectedRoomId, setSelectedRoomId] = useState<string | null>(
    searchParams.get('roomId') ?? null
  )
  const [displayName, setDisplayName] = useState<string | null>(
    localStorage.getItem('displayName')
  )
  const [userId, setUserId] = useState<string | null>(
    localStorage.getItem('userId')
  )
  const [userSecret, setUserSecret] = useState<string | null>(
    localStorage.getItem('userSecret')
  )
  const [serverUrl, setServerUrl] = useState<string | null>(
    localStorage.getItem('serverUrl')
  )
  const [newUser, setNewUser] = useState<z.infer<
    typeof userCreatedSchema
  > | null>(null)
  const [supporterList, setSupporterList] = useState<
    z.infer<typeof supporterListSchema>
  >([])
  const [newOpCenter, setNewOpCenter] = useState<z.infer<
    typeof opCenterCreatedSchema
  > | null>(null)
  const [opCenterList, setOpCenterList] = useState<
    z.infer<typeof adminOpCenterListSchema>
  >([])

  useEffect(() => {
    console.log('connect')
    if (!serverUrl || !userId || !userSecret || !displayName) {
      console.log('reset')

      setSocket(null)
      return
    }
    const socket = io(serverUrl, { auth: { userId, userSecret } })
    setSocket(socket)
    console.log('connected')

    return () => {
      console.log('disconnect')
      socket.disconnect()
    }
  }, [serverUrl, userId, userSecret, displayName])

  useEffect(() => {
    if (!socket) return
    socket.on('whoRU', () => {
      socket.emit('IAmSupport', {
        user: { name: displayName, role: 'supporter' },
        roomId: 'lobby',
      } as z.infer<typeof IAmSupportSchema>)
    })
    socket.on('roomList', ({ rooms }: z.infer<typeof roomListSchema>) => {
      setRooms(rooms)
    })
    socket.on(
      'supporterList',
      (supporters: z.infer<typeof supporterListSchema>) => {
        setSupporterList(supporters)
      }
    )
    socket.on(
      'unreadMessagesUpdate',
      ({
        roomId,
        unreadUserMessages,
      }: z.infer<typeof unreadMessagesUpdateSchema>) => {
        setRooms((rooms) => [
          ...rooms.map((r) =>
            r.id === roomId ? { ...r, unreadUserMessages } : r
          ),
        ])
      }
    )
    socket.on('userCreated', (user: z.infer<typeof userCreatedSchema>) =>
      setNewUser(user)
    )
    socket.on(
      'opCenterCreated',
      (opCenter: z.infer<typeof opCenterCreatedSchema>) =>
        setNewOpCenter(opCenter)
    )
    socket.on(
      'opCenterList',
      (opCenters: z.infer<typeof adminOpCenterListSchema>) =>
        setOpCenterList(opCenters)
    )

    setTimeout(() => socket.emit('listRooms', {}), 50)
  }, [socket, displayName])

  function countUnreadMessages(room: z.infer<typeof roomSchema>) {
    return Object.entries(room.unreadUserMessages ?? {})
      .filter(([key]) => key !== userId)
      .map(([, count]) => count)
      .reduce((acc, count) => acc + count, 0)
  }

  const selectedRoom = rooms.find((r) => r.id === selectedRoomId)

  return !displayName || !serverUrl || !userId || !userSecret ? (
    <SignIn
      onSignIn={(displayName, serverUrl, userId, userSecret) => {
        localStorage.setItem('displayName', displayName)
        setDisplayName(displayName)
        localStorage.setItem('serverUrl', serverUrl)
        setServerUrl(serverUrl)
        localStorage.setItem('userId', userId)
        setUserId(userId)
        localStorage.setItem('userSecret', userSecret)
        setUserSecret(userSecret)
      }}
    />
  ) : (
    <div className="h-screen w-screen flex flex-row gap-4">
      <div className="w-64 flex flex-col gap-2 bg-white">
        <div className="flex flex-col gap-2 p-4 min-h-48">
          <div className="flex flex-row justify-between gap-2">
            <div className="text-lg font-bold mx-auto">Rooms</div>
            <RefreshCcw
              className="hover:animate-spin"
              onClick={() => socket && socket.emit('listRooms', {})}
            />
            <LogOut
              onClick={() => {
                localStorage.removeItem('userId')
                localStorage.removeItem('userSecret')
                setUserId(null)
                setUserId(null)
              }}
            />
          </div>
          {rooms.map((room) => (
            <div
              key={room.id}
              className={`p-2 rounded-lg border border-gray-500 text-sm hover:bg-gray-100 cursor-pointer flex flex-row justify-between ${
                selectedRoomId === room.id ? 'bg-blue-500/25' : ''
              }`}
              onClick={() => setSelectedRoomId(room.id)}
            >
              {room.name ?? room.id}
              {countUnreadMessages(room) > 0 && (
                <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 border border-red-700 ring-1 ring-inset ring-purple-700/10">
                  {countUnreadMessages(room)}
                </span>
              )}
            </div>
          ))}
        </div>
        <div className="flex flex-col items-stretch">
          <div className="text-lg font-bold mx-auto">Supporter</div>
          <button
            className="m-2 p-2 border border-black rounded-lg"
            onClick={() => socket?.emit('createUser', {})}
          >
            Create
          </button>
          <Modal isOpen={!!newUser} onClose={() => setNewUser(null)}>
            <div
              className="bg-white rounded-2xl border border-black p-4"
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
              }}
            >
              <div className="flex flex-col gap-4 p-2">
                <span className="text-lg font-extrabold">New supporter</span>
                <span>UserId:</span>
                <input
                  readOnly
                  className="w-[350px] border border-black rounded-lg p-2"
                  value={newUser?.userId}
                />
                <span>UserSecret:</span>
                <input
                  readOnly
                  className="w-[350px] border border-black rounded-lg p-2"
                  value={newUser?.userSecret}
                />
                <button
                  className="p-2 border border-black rounded-lg"
                  onClick={() => setNewUser(null)}
                >
                  Close
                </button>
              </div>
            </div>
          </Modal>

          <button
            className="m-2 p-2 border border-black rounded-lg"
            onClick={() => socket?.emit('listSupporters', {})}
          >
            List
          </button>
          <Modal
            isOpen={supporterList.length > 0}
            onClose={() => setSupporterList([])}
          >
            <div
              className="bg-white rounded-2xl border border-black p-4 max-h-[80%]"
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
              }}
            >
              <div className="flex flex-col gap-4 p-2 max-h-full">
                <span className="text-lg font-extrabold">Users</span>
                <div className="flex flex-1 overflow-y-auto">
                  <table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>UserId</th>
                        <th>UserSecret</th>
                      </tr>
                    </thead>
                    <tbody>
                      {supporterList.map((supporter) => (
                        <tr key={supporter.userId}>
                          <td>
                            <span className="p-2 m-2">{supporter.name}</span>
                          </td>
                          <td>
                            {supporter.userId && (
                              <input
                                className="w-[350px] p-2 m-2 border border-black rounded-lg"
                                type="text"
                                readOnly
                                value={supporter.userId}
                              />
                            )}
                          </td>
                          <td>
                            {supporter.userSecret && (
                              <input
                                className="w-[350px] p-2 m-2 border border-black rounded-lg"
                                type="text"
                                readOnly
                                value={supporter.userSecret}
                              />
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <button
                  className="p-2 border border-black rounded-lg"
                  onClick={() => setSupporterList([])}
                >
                  Close
                </button>
              </div>
            </div>
          </Modal>
        </div>
        <div className="flex flex-col items-stretch">
          <div className="text-lg font-bold mx-auto">OP Centers</div>
          <button
            className="m-2 p-2 border border-black rounded-lg"
            onClick={() => socket?.emit('createOpCenter', {})}
          >
            Create
          </button>
          <Modal isOpen={!!newOpCenter} onClose={() => setNewOpCenter(null)}>
            <div
              className="bg-white rounded-2xl border border-black p-4"
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
              }}
            >
              <div className="flex flex-col gap-4 p-2">
                <span className="text-lg font-extrabold">New OP Center</span>
                <span>ClientId:</span>
                <input
                  className="w-[350px] border border-black rounded-lg p-2"
                  value={newOpCenter?.clientId}
                />
                <span>ClientSecret:</span>
                <input
                  className="w-[350px] border border-black rounded-lg p-2"
                  value={newOpCenter?.clientSecret}
                />
                <button
                  className="p-2 border border-black rounded-lg"
                  onClick={() => setNewOpCenter(null)}
                >
                  Close
                </button>
              </div>
            </div>
          </Modal>
          <button
            className="m-2 p-2 border border-black rounded-lg"
            onClick={() => socket?.emit('listOpCenters', {})}
          >
            List
          </button>
          <Modal
            isOpen={opCenterList.length > 0}
            onClose={() => setOpCenterList([])}
          >
            <div
              className="bg-white rounded-2xl border border-black p-4 max-h-[80%]"
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
              }}
            >
              <div className="flex flex-col gap-4 p-2 max-h-full">
                <span className="text-lg font-extrabold">OP Centers</span>
                <div className="flex flex-1 overflow-y-auto">
                  <table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>ClientId</th>
                        <th>ClientSecret</th>
                        <th>AppVersion</th>
                        <th>Chats</th>
                      </tr>
                    </thead>
                    <tbody>
                      {opCenterList.map((opCenter) => (
                        <tr key={opCenter.id}>
                          <td>
                            <span className="p-2 m-2">{opCenter.name}</span>
                          </td>
                          <td>
                            {opCenter.id && (
                              <input
                                className="w-[350px] p-2 m-2 border border-black rounded-lg"
                                type="text"
                                readOnly
                                value={opCenter.id}
                              />
                            )}
                          </td>
                          <td>
                            {opCenter.clientSecret && (
                              <input
                                className="w-[350px] p-2 m-2 border border-black rounded-lg"
                                type="text"
                                readOnly
                                value={opCenter.clientSecret}
                              />
                            )}
                          </td>
                          <td>{opCenter.appVersion}</td>
                          <td>{opCenter.openChats}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <button
                  className="p-2 border border-black rounded-lg"
                  onClick={() => setOpCenterList([])}
                >
                  Close
                </button>
              </div>
            </div>
          </Modal>
        </div>
      </div>
      <div className="flex-1">
        {selectedRoom && (
          <SupportChat
            displayName={displayName}
            serverUrl={serverUrl}
            userId={userId}
            userSecret={userSecret}
            room={selectedRoom}
            readAllMessages={() =>
              setRooms((rooms) =>
                rooms.map((room) =>
                  room.id === selectedRoomId
                    ? {
                        ...room,
                        unreadUserMessages: Object.fromEntries(
                          Object.entries(room.unreadUserMessages).map(
                            ([key, count]) =>
                              key === userId ? [key, count] : [key, 0]
                          )
                        ),
                      }
                    : room
                )
              )
            }
          ></SupportChat>
        )}
      </div>
    </div>
  )
}

export default App
