import { messageSchema } from 'chat-schemas'
import { userSchema } from 'chat-schemas/user.schema'
import { User } from 'lucide-react'
import Moment from 'react-moment'
import { z } from 'zod'

export function UserMessage({
  message,
  user,
  setCarouselData,
}: {
  message: z.infer<typeof messageSchema>
  user?: z.infer<typeof userSchema>
  setCarouselData: (
    data: { images: string[]; startIndex: number } | null
  ) => void
}) {
  return (
    <div className="flex items-start space-x-2 flex-row-reverse space-x-reverse">
      <div className="flex-shrink-0 w-8 h-8 rounded-full flex items-center justify-center bg-blue-500">
        <User size={20} color="white" />
      </div>
      <div className="max-w-[70%] p-3 rounded-lg bg-blue-500 text-white shadow text-right flex flex-col gap-2">
        {user && <span className="font-bold">{user.name}</span>}
        <span>{message.message}</span>
        <span className="text-xs">
          <Moment interval={1000} fromNow>
            {message.time}
          </Moment>
        </span>
        {message.images.length > 0 && (
          <div
            className="flex flex-row gap-2 justify-end overflow-auto"
            style={{ minWidth: 'content' }}
          >
            {message.images.map((img, i) => (
              <img
                key={i}
                className="h-12"
                src={img.thumbnail}
                alt="123"
                onClick={() =>
                  setCarouselData({
                    images: message.images.map(({ src }) => src),
                    startIndex: i,
                  })
                }
              />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
