import { ArrowLeft, ArrowRight } from 'lucide-react'
import { useState } from 'react'

export function Carousel({
  images,
  startIndex,
}: {
  images: string[]
  startIndex: number
}) {
  const [index, setIndex] = useState(startIndex)

  const prevEnabled = index > 0
  const nextEnabled = index < images.length - 1

  return (
    <div className="flex flex-row justify-between items-center w-full p-4">
      <div
        className={
          'rounded-[50%] p-8' +
          (prevEnabled
            ? ' bg-blue-300/50 hover:bg-blue-300/75'
            : ' text-gray-500')
        }
        onClick={(e) => {
          e.stopPropagation()
          if (!prevEnabled) return
          setIndex((index) => index - 1)
        }}
      >
        <ArrowLeft className="w-24 h-24" />
      </div>
      <img
        onClick={(e) => e.stopPropagation()}
        className="max-w-[75%] max-h-3/4"
        src={images[index]}
        alt="uploaded"
      />
      <div
        className={
          'rounded-[50%] p-8' +
          (nextEnabled
            ? ' bg-blue-300/50 hover:bg-blue-300/75'
            : ' text-gray-500')
        }
        onClick={(e) => {
          e.stopPropagation()
          if (!nextEnabled) return
          setIndex((index) => index + 1)
        }}
      >
        <ArrowRight className="w-24 h-24" />
      </div>
    </div>
  )
}
