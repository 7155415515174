import { useEffect, useState } from 'react'

export function WritingDots() {
  const [dots, setDots] = useState('')

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => {
        if (prevDots.length >= 3) return ''
        return prevDots + '.'
      })
    }, 500)

    return () => clearInterval(interval)
  }, [])

  return <span>{dots}&nbsp;</span>
}
