export function Modal({
  children,
  isOpen,
  onClose,
}: {
  children: React.ReactNode
  isOpen: boolean
  onClose: () => void
}) {
  return (
    <div
      className={
        'absolute top-0 left-0 z-20 flex flex-col justify-center items-center h-screen w-screen bg-gray-300/50 ' +
        (isOpen ? '' : ' hidden')
      }
      onClick={() => onClose()}
    >
      {children}
    </div>
  )
}
