import { userSchema } from 'chat-schemas/user.schema'
import { User } from 'lucide-react'
import { z } from 'zod'
import { WritingDots } from './WritingDots'

export function PlaceholderMessage({
  user,
}: {
  user?: z.infer<typeof userSchema>
}) {
  return (
    <div className="flex items-start space-x-2 flex-row-reverse space-x-reverse">
      <div className="flex-shrink-0 w-8 h-8 rounded-full flex items-center justify-center bg-blue-500">
        <User size={20} color="white" />
      </div>
      <div className="min-w-12 max-w-[70%] p-3 rounded-lg bg-blue-500 text-white shadow text-right flex flex-col">
        {user && <span className="font-bold">{user.name}</span>}
        <WritingDots />
      </div>
    </div>
  )
}
