import { messageSchema } from 'chat-schemas'
import { userSchema } from 'chat-schemas/user.schema'
import { Headphones } from 'lucide-react'
import Moment from 'react-moment'
import { z } from 'zod'

export function SupportMessage({
  message,
  isRead,
}: {
  message: z.infer<typeof messageSchema>
  users: z.infer<typeof userSchema>[]
  isRead: boolean
}) {
  return (
    <div className="flex items-start space-x-2 flex-row">
      <div className="flex-shrink-0 w-8 h-8 rounded-full flex items-center justify-center bg-blue-500">
        <Headphones size={20} color="white" />
      </div>
      <div
        className={`max-w-[70%] p-3 rounded-lg bg-white text-gray-800 shadow flex flex-col ${isRead ? '' : 'ring-2 ring-blue-500 font-bold'}`}
      >
        <span className="font-bold">Du</span>
        <span>{message.message}</span>
        <span className="text-xs">
          <Moment interval={1000} fromNow>
            {message.time}
          </Moment>
        </span>
      </div>
    </div>
  )
}
