import { useState } from 'react'

export function SignIn({
  onSignIn,
}: {
  onSignIn: (
    displayName: string,
    serverUrl: string,
    clientId: string,
    clientSecret: string
  ) => void
}) {
  const [serverUrl, setServerUrl] = useState(
    localStorage.getItem('serverUrl') || ''
  )
  const [clientId, setClientId] = useState(
    localStorage.getItem('clientId') || ''
  )
  const [clientSecret, setClientSecret] = useState(
    localStorage.getItem('clientSecret') || ''
  )
  const [displayName, setDisplayName] = useState(
    localStorage.getItem('displayName') || ''
  )

  return (
    <div className="w-screen h-screen flex flex-col justify-center items-center">
      <div className="w-[500px] border border-gray-200 shadow rounded-md flex flex-col gap-4">
        <div className="flex flex-row justify-center bg-gray-400 p-4 rounded-t-md">
          Login
        </div>
        <div className="flex flex-col gap-4 p-4">
          <div className="flex flex-row justify-between items-center">
            <span>Display Name</span>
            <input
              className="w-[350px] p-2 border border-gray-600 rounded"
              type="text"
              value={displayName}
              onChange={(e) => setDisplayName(e.target.value)}
              onKeyDown={(e) =>
                e.key === 'Enter' &&
                displayName &&
                clientId &&
                clientSecret &&
                onSignIn(displayName, serverUrl, clientId, clientSecret)
              }
            />
          </div>{' '}
          <div className="flex flex-row justify-between items-center">
            <span>Serverurl</span>
            <input
              className="w-[350px] p-2 border border-gray-600 rounded"
              type="text"
              value={serverUrl}
              onChange={(e) => setServerUrl(e.target.value)}
              onKeyDown={(e) =>
                e.key === 'Enter' &&
                displayName &&
                clientId &&
                clientSecret &&
                onSignIn(displayName, serverUrl, clientId, clientSecret)
              }
            />
          </div>
          <div className="flex flex-row justify-between items-center">
            <span>ClientId</span>
            <input
              className="w-[350px] p-2 border border-gray-600 rounded"
              type="text"
              value={clientId}
              onChange={(e) => setClientId(e.target.value)}
              onKeyDown={(e) =>
                e.key === 'Enter' &&
                displayName &&
                clientId &&
                clientSecret &&
                onSignIn(displayName, serverUrl, clientId, clientSecret)
              }
            />
          </div>
          <div className="flex flex-row justify-between items-center">
            <span>ClientSecret</span>
            <input
              className="w-[350px] p-2 border border-gray-600 rounded"
              type="password"
              value={clientSecret}
              onChange={(e) => setClientSecret(e.target.value)}
              onKeyDown={(e) =>
                e.key === 'Enter' &&
                displayName &&
                clientId &&
                clientSecret &&
                onSignIn(displayName, serverUrl, clientId, clientSecret)
              }
            />
          </div>
          <div className="flex flex-row justify-center">
            <button
              disabled={!serverUrl || !clientId || !clientSecret}
              className="w-full bg-blue-500 text-white p-2 rounded"
              onClick={() =>
                serverUrl &&
                displayName &&
                clientId &&
                clientSecret &&
                onSignIn(displayName, serverUrl, clientId, clientSecret)
              }
            >
              Login
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
